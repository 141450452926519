import { useRouter } from 'next/router';
import React, { FC } from 'react';

import Button from '@/components/core/Button';

import cn from './style.module.sass';

const NotFoundPage: FC = () => {
	const { push } = useRouter();

	const goToHome = async () => {
		await push('/');
	};

	return (
		<div className={cn.wrapper}>
			<div className={cn.number}>404</div>
			<div className={cn.title}>Страница не найдена</div>
			<div className={cn.subtitle}>
				Возможно, неправильно набран адрес страницы или такой страницы на сайте не существует
			</div>
			<div className={cn.button}>
				<Button variant="outlined" handleClick={goToHome}>
					Главная страница
				</Button>
			</div>
		</div>
	);
};

export default NotFoundPage;
