import React from 'react';

import NotFoundPage from '@/components/features/service/NotFoundPage';
import MainLayout from '@/components/shared/layouts/MainLayout/main';

const NotFound = () => (
	<MainLayout>
		<NotFoundPage />
	</MainLayout>
);

export default NotFound;
